// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jbkgrnrX{background:#2e2f33}.Z1NsfLut{padding-top:56.25%;position:relative;width:100%}.Z1NsfLut>*{position:absolute!important}.ijT71KL5{color:#fff;left:50%;top:50%;transform:translate(-50%,-50%) scale(2)}.Kr64Vzmj{left:0;top:0}.Kr64Vzmj,.Kr64Vzmj iframe{height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "jbkgrnrX",
	"proportion": "Z1NsfLut",
	"spinner": "ijT71KL5",
	"iframeWrapper": "Kr64Vzmj"
};
module.exports = ___CSS_LOADER_EXPORT___;
