
import Vue from "vue"
import type { FullSession } from "@/types/api/program"

export default Vue.extend({
  props: {
    session: {
      type: Object as VuePropType<Nullable<FullSession>>,
      default: null,
    },
  },

  data: () => ({
    updateIntervalId: undefined as Timer,
  }),

  computed: {
    embedCode (): string {
      return this.session?.track.liveData.embedCode || ""
    },
    isEmbedCodeHtml (): boolean {
      return this.embedCode.startsWith("<iframe")
    },
  },

  mounted () {
    if (this.isEmbedCodeHtml)
      this.updateIntervalId =
        setInterval(() => { this.$emit("update") }, 30 * 1000)
  },
  beforeDestroy () {
    clearInterval(this.updateIntervalId)
  },
})
